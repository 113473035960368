<template>
  <div ref="gwn" class="gwn">
    <van-sticky>
      <van-tabs v-model="tabActive" class="mb10" sticky>
        <van-tab v-for="(item,index) in tabs" :key="index" :title="item.name" />
      </van-tabs>
    </van-sticky>

    <!-- <div v-if="tabActive !== 0" class="section mb10">
      <div class="section-row">
        <div class="section-studio" @click="onStudioPickerOpen">
          <span class="van-ellipsis">{{ studio.label }}</span>
          <van-icon name="arrow-down" color="#666" />
        </div>
        <div class="section-date">
          <div class="section-date-start" @click="onDatePickerOpen('startDate')">
            {{ startDate }}
          </div>
          <div class="section-date-end" @click="onDatePickerOpen('endDate')">
            {{ endDate }}
          </div>
        </div>
      </div>

    </div> -->
    <van-popup v-model="dateShowPicker" position="bottom">
      <van-datetime-picker
        v-model="currentTime"
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="onDatePickerConfirm"
        @cancel="onDatePickerClose"
      />
    </van-popup>
    <van-popup v-model="studioShowPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="studioColumns"
        value-key="label"
        @cancel="onStudioPickerClose"
        @confirm="onStudioPickerConfirm"
      />
    </van-popup>
    <div class="tab-container">
      <component :is="tabs[tabActive].componentName" :ref="tabs[tabActive].componentName" />
    </div>

  </div>
</template>
<script>
import PageMixin from '@/mixins/page'
import { Tab, Tabs, Field, DatetimePicker, Picker, Tag, Grid, GridItem, Sticky } from 'vant'
import AnalysisContract from './components/studioDailyReport'
import AnalysisLeads from './components/employeeWeeklyReport'
import AnalysisDemo from './components/demo'
import AnalysisDaily from './components/employeeDailyReport'
import AnalysisSchedule from './components/studioWeeklyReport'
import dayjs from 'dayjs'
import gwm from 'gwm'

export default {
  name: 'CampusAnalysis',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [DatetimePicker.name]: DatetimePicker,
    [Picker.name]: Picker,
    [Tag.name]: Tag,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Sticky.name]: Sticky,
    AnalysisContract,
    AnalysisLeads,
    AnalysisDemo,
    AnalysisDaily,
    AnalysisSchedule,
  },
  mixins: [PageMixin],
  data() {
    const nowDate = dayjs(nowDate).format('YYYY-MM-DD')
    const startYear = dayjs().startOf('year').format('YYYY-MM-DD')
    const startMonth = dayjs().startOf('month').format('YYYY-MM-DD')
    const startWeek = dayjs().startOf('week').format('YYYY-MM-DD')
    const endWeek = dayjs().endOf('week').format('YYYY-MM-DD')
    return {
      tabs: [
        { name: '员工日报', componentName: 'AnalysisDaily', grid: 'analysis_getschedulecount' },
        { name: '校区日报', componentName: 'AnalysisContract', grid: 'analysis_getcontractcount' },
        { name: '员工周报', componentName: 'AnalysisLeads', grid: 'analysis_getleadscount' },
        { name: '校区周报', componentName: 'AnalysisDemo', grid: 'analysis_getdemocount' },
        // { name: 'TMK', componentName: 'AnalysisSchedule', grid: 'analysis_getschedulecount' },
        
      ],
      tabActive: 0,
      tagList: [{ label: '本周', startDate: startWeek, endDate: nowDate }, { label: '本月', value: 'month', startDate: startMonth, endDate: nowDate }, { label: '本年', value: 'year', startDate: startYear, endDate: nowDate }],
      tagActive: 0,
      dateType: 'startDate', // startDate,endDate
      startDate: startWeek,
      endDate: endWeek,
      currentTime:'',
      currentStart: dayjs().startOf('month').$d,
      currentEnd: new Date(),
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(),
      dateShowPicker: false,
      // 校区
      studio: {label:'我的',value:0},
      studioColumns: [
        {label:'我的',value:0},
        {label:'我下属的',value:1}
      ],
      studioShowPicker: false,
      attendList:[],
      attendNav:['名称','Leads','跟进数','高意向','诺访','实到'],
      tmkNav:['名称','新增','跟进'],
      
    }
  },
  mounted() {
    this.$nextTick(()=>{
      gwm.creation({
        container:this.$refs.gwn,
        txt:localStorage.getItem('employee_watermark'),
        mode: 'svg',
        watch: false,
        fontSize: 11,
        height:80,
        x: 20,
        y: 30,
        color: '#bbbfca',
        font: 'sans-serif',
        alpha: 0.2,
        angle: -15
      })
    })
  },
  methods: {
    updateChart() {
      this.$refs[this.tabs[this.tabActive].componentName].getchartDatas()
    },
    getAttend(){
      let apiUrl
      if(this.tabActive === 1){
        apiUrl = 'crm_customer_attend'
      }
      if(this.tabActive === 0){
        apiUrl = 'crm_analysis_tmk_list'
      }
      if(!apiUrl){
        return false
      }
      const params = {}
      params.start_date = this.startDate || ''
      params.end_date = this.endDate || ''
      params.is_sub = this.studio.value
      this.$api[apiUrl](params).then(res => {
        this.attendList = res.data
      })
    },
    onStudioPickerClose() {
      this.studioShowPicker = false
    },
    onStudioPickerOpen() {
      this.studioShowPicker = true
    },
    onStudioPickerConfirm(value) {
      this.studio = value
      this.onStudioPickerClose()
      this.getAttend()
      this.updateChart()
    },
    // 开始结束时间
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      } else {
        return `${val}日`
      }
    },
    onDatePickerOpen(type) {
      if(type == 'startDate'){
        this.currentTime = this.currentStart
      } else{
        this.currentTime = this.currentEnd
      }
      this.dateType = type
      this.dateShowPicker = true
    },
    onDatePickerClose() {
      this.dateShowPicker = false
    },
    onDatePickerConfirm(time) {
      if (this.dateType === 'endDate') {
        this.currentEnd = time;
        const timestampEnd = dayjs(time).valueOf()
        const timestampStart = dayjs(this.startDate).valueOf()
        if (timestampEnd < timestampStart) {
          this.$toast('结束时间不能小于开始时间')
          return
        }
      }else{
        this.currentStart = time;
      }
      this[this.dateType] = dayjs(time).format('YYYY-MM-DD')
      this.onDatePickerClose()
      this.getAttend()
      this.updateChart()
    },
    // 时间区间推荐
    onTagClick(index) {
      this.tagActive = index
      const { startDate, endDate } = this.tagList[index]
      this.startDate = startDate
      this.endDate = endDate
      this.getAttend()
      this.updateChart()
    },
  }
}
</script>

<style lang="less" scoped>
  .van-tag--primary{
      background-color: #00DE93;
    }

  .container{
    padding: 10px 0;
  }

  .grid-title{
    font-size: 16px;
    color: #333;
  }

  .tab-container{
    margin-top: 10px;
  }

  .section{
    background: #fff;

    &-row{
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &-row2{
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    &-studio{
      box-sizing: border-box;
      width: 120px;
      height: 30px;
      border-radius: 4px;
      padding: 4px 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #666;
      font-size: 14px;
      border: 1px solid #eee;
       justify-content: space-between;
    }
    &-date{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &-start{
        margin-left: 10px;
        box-sizing: border-box;
        width: 100px;
        height: 30px;
        border-radius: 4px;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;
        font-size: 12px;
        border: 1px solid #eee;
      }
      &-end{
        margin-left: 10px;
        box-sizing: border-box;
        width: 100px;
        height: 30px;
        border-radius: 4px;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #666;
        font-size: 12px;
        border: 1px solid #eee;
      }
    }
    &-commend{
      border: 1px solid #00DE93;
      border-radius: 16px;
      overflow: hidden;
    .van-tag{
        padding: 4px 20px;
        font-size: 16px;
        border-radius: 0;
    }
    .van-tag::after{
      border: none;
      border-radius:none;
    }
    .van-tag--plain{
      color: #00DE93;
      background-color: #fff;
    }
  }
  }
  .crm-item{
    background: #fff;
    padding: 0 10px;
    .van-col{
      text-align: center;
      height: 40px;
      line-height: 40px;
    }
    .chart-title{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      font-size: 16px;
      color: #333;
      font-weight: bold;
    }
    .crm-item-name{
      overflow: hidden;
      text-overflow:ellipsis;/* 超出部分显示省略号 */
      white-space: nowrap;/*规定段落中的文本不进行换行 */
    }
  }
</style>
<style lang="less">
  .crm-item{
    .van-sticky--fixed{
      padding: 0 10px;
      background: #fff;
    }
  }
  .gwn{
    min-height: 100vh;
    .v-table-views{
      background-color: rgba(0,0,0,0) !important;
    }
    .v-table-header{
      background-color: rgba(0,0,0,0) !important;
    }
  }
</style>

