<template>
  <div class="container">
    <div v-for="(chart,chartIndex) in chartList" :key="chartIndex" class="chart-box">
      <div class="chart-title">{{ chart.title }}</div>
      <EasyLine
        class="chart-img"
        :easyData="chart.chartData"
        :customConfigs="currentCustomConfigs"
      />
    </div>
  </div>
</template>
<script>
import { Tag } from 'vant'
import VeLine from 'v-charts/lib/line.common'
import EasyLine from '@/components/EasyLine/line'

export default {
  name: 'AnalysisSchedule',
  components: {
    [VeLine.name]: VeLine,
    [Tag.name]: Tag,
    [EasyLine.name]: EasyLine,
  },
  data() {
    return {
      title: '教务统计',
      chartList: [
        { title: 'TMK', tagList: [{ label: '日', value: 'day' }, { label: '月', value: 'month' }, { label: '年', value: 'year' }], tagActive: 0, api: 'crm_analysis_tmk', chartData: {}},
      ],
      currentCustomConfigs:['rate'],
    }
  },
  created() {
    this.getchartDatas()
  },
  methods: {
    getchartDatas() {
      this.$toast.loading({
        message: '图表加载中...',
        forbidClick: true
      })
      const parentPageObj = this.$parent
      const start_date = parentPageObj.startDate !== '' ? parentPageObj.startDate : ''
      const end_date = parentPageObj.endDate !== '' ? parentPageObj.endDate : ''
      const is_sub = parentPageObj.studio.value
      this.chartList.forEach((item, index) => {
        const tagValue = this.chartList[index].tagList[this.chartList[index].tagActive].value
        this.$api[item.api]({ filter_type: tagValue, start_date, end_date, is_sub })
          .then(res => {
            this.chartList[index].chartData = res.data
          })
          .finally(() => {
            this.$toast.clear()
          })
      })
    }
  }
}
</script>

<style lang="less" scoped>
    .chart-box{
        box-sizing: border-box;
        background: #fff;
        margin-bottom: 20px;
        padding: 0 15px;
    }
    .chart{
        &-title{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            font-size: 16px;
            color: #333;
            font-weight: bold;
        }
        &-right{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            // margin-bottom: 20px;
            .section-commend{
              border: 1px solid #00DE93;
              border-radius: 16px;
              overflow: hidden;
            }
            .van-tag::after{
              border: none;
              border-radius:none;
            }
            .van-tag{
                padding: 4px 10px;
                font-size: 14px;
                border-radius: 0;
            }
        }
    }
</style>

